import { DndContext } from '@smartplatform/ui';
// import 'bootstrap-4-grid/scss/grid.scss';
import { observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import Layout from './layout';
import { Login, Register, Restore, Reset } from 'components/auth';
import store from './store';
import './styles/main.scss';
import { Notifications } from 'components';
import { getAvailableModules } from 'client/tools';
import { AuthLayout } from './components/auth/components/auth-layout';
import loadable from '@loadable/component';
import { RewritePassword } from 'components/rewrite-password';
import { SocialAuth } from 'components/auth/social-network/soc-auth/SocialAuth';
import { TwoFactorConfirm } from 'components/two-factor';

// не убирать! после билда необходимо чтобы favicon оставалась в статике
import favicon from 'client/img/favicon.ico?url';

const Profile = loadable(() => import('./components/profile'));
const Test = loadable(() => import('./components/test'));

const App = () => {
	if (!store.isInitialized) return null;
	if (!store.model.isAuthorized) {
		return (
			<Router history={store.history}>
				<AuthLayout>
					<div className='auth'>
						<Notifications />
						<Switch>
							{store.model.User.INFO.CREATE && <Route path='/register' component={Register} />}
							<Route path='/restore' component={Restore} />
							<Route path='/login' component={Login} />
							<Route path='/reset-password/:token' component={Reset} />
							<Route path='/auth/:provider' component={SocialAuth} />
							<Route path='/2fa/:type/:token' component={TwoFactorConfirm} />
							<Redirect from='/*' to='/login' />
						</Switch>
					</div>
				</AuthLayout>
			</Router>
		);
	}

	const _passwordExpirationTime = parseInt(store.mergedConfig?.authentication?.password?.passwordExpirationTime);
	const enableRewrite = !!_passwordExpirationTime && _passwordExpirationTime !== 0;

	if (enableRewrite && (!store.model.user.passwordTimeout || new Date() > new Date(store.model.user.passwordTimeout))) {
		return (
			<Router history={store.history}>
				<AuthLayout>
					<div className='auth'>
						<Notifications />
						<Route path='/' exact>
							<Redirect to='/rewrite-password' />
						</Route>
						<Route path='/rewrite-password' component={RewritePassword} />
						<Redirect from='/*' to='/rewrite-password' />
					</div>
				</AuthLayout>
			</Router>
		);
	}

	const className = 'project-vars';

	const modulesRoutes = [];
	store.availableModules = getAvailableModules();
	for (let module of store.availableModules) {
		const Module = module.component;
		modulesRoutes.push(<Module key={module.code} />);
	}

	return (
		<DndContext className={className} key={store.appKey}>
			<Router history={store.history}>
				<Layout>
					<Notifications />
					{modulesRoutes}
					<Switch>
						<Route path='/' exact>
							<Redirect to='/management/tasks' />
						</Route>
						<Route path='/management/' exact>
							<Redirect to='/management/tasks' />
						</Route>
						{/*<Route path={MAIN_DASHBOARD_PATH} component={withTitleAndLink(MainDashboard, { title: t('mainDashboard') })} />*/}
						<Route path='/profile' component={Profile} />
						<Route path='/2fa/:type/:token' component={TwoFactorConfirm} />
						<Route path='/test' component={Test} />
					</Switch>
				</Layout>
			</Router>
		</DndContext>
	);
};

export default observer(App);
